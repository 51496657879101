::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #dce0e8;
}

.root {
  background-color: #f3f4f6;
  box-sizing: border-box;
  font-family: 'Maven Pro', Sans, sans-serif;
  font-size: 13px;
  color: #060505;
  -webkit-font-smoothing: subpixel-antialiased;

  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 5px 20px 0;

  & a {
    color: #252525;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      /* TODO: Change to styleVariables.colorAdjustBlue when refactored to CSS-in-JS */
      color: #00bed5;
    }
  }
}

.header {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.logoContainer {
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
}

.menuButton {
  color: #96a1bd;
  font-size: 15px;
  text-transform: uppercase;
}

.logoContainer:hover .menuButton {
  color: #8a95b3;
}

.headerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.header {
  color: #4b577e;
}

.body {
  box-sizing: border-box;
  margin: 0 auto;
  flex: 1;
  height: 100vh;
  width: 100%;

  overflow: auto;
}

.adminLinksContainer {
  position: absolute;
  right: 1rem;

  & .adminLink {
    color: transparent;

    &:hover {
      color: #4b577e;
    }
  }

  & .visibleLink {
    color: #4b577e;
    padding-left: 0.5rem;
    font-size: 13px;

    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}

.reportDropdownContainer {
  padding-top: 2px;
  padding-bottom: 2px;
  & ::-webkit-scrollbar {
    width: 6px;
  }
}
.reportActiveDropdownContainer {
  /* 2 is used for fixed part of the table. Dropdown should be on top of it.*/
  z-index: 30;
  font-size: 13px;
}

.reportDropdownTarget {
  color: #4b577e;
  font-size: 16px;
}

.reportDropdownOptionLink {
  display: contents;
  color: white !important;
}

.reportDropdownDummyText {
  line-height: 25px;
  border-bottom: 2px solid #4b577e;
  z-index: 2;
}
