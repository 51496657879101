@import '~react-dates/lib/css/_datepicker.css';

.DateRangePickerInput__withBorder {
  border: solid 1px #c2c2c2;
}

.DateRangePicker_picker__portal {
  z-index: 1400;
}

.DateInput {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal;
  padding: 4px;
  width: 105px;
}

.DateInput_input {
  box-sizing: border-box;
  border: 0;
  caret-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  padding: 0 8px;
}

.DateInput_input__focused {
  background: #4285f4;
  border-radius: 2px;
  color: #fff;
}

.DateRangePickerInput {
  border: solid 1px #c2c2c2;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.DateRangePickerInput_calendarIcon {
  margin: 0;
  padding: 0 0 0 12px;
  outline: none;
  height: 24px;
  color: #82888a;
}

.DateRangePickerInput__showClearDates {
  padding-right: 24px;
}

.DateRangePickerInput_clearDates {
  padding: 0;
}

.DateRangePickerInput_clearDates:focus,
.DateRangePickerInput_clearDates:hover {
  background: initial;
  border-radius: 0;
}

.DateRangePickerInput_clearDates:focus svg,
.DateRangePickerInput_clearDates:hover svg {
  fill: #757575;
}

.CalendarDay__selected_span {
  background: #d8e6fe;
  color: rgba(0, 0, 0, 0.87);
  border: 1px double #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover,
.CalendarDay__selected_span:active {
  background: rgba(66, 133, 244, 0.4);
  border: 1px double #fff;
  color: initial;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__selected_start,
.CalendarDay__selected_end,
.CalendarDay__selected {
  background: #4285f4;
  border-color: #fff;
  color: #fff;
}

.CalendarDay__selected_start :active,
.CalendarDay__selected_end:active,
.CalendarDay__selected:active {
  background: #4285f4;
}

.DayPicker__withBorder {
  border-radius: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid #fff;
  border-right: 33px solid #4285f4;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #d8e6fe;
}

.DateRangePicker--lastDays .DateRangePicker_picker__directionLeft {
  transform: translateX(60px);
}
