.inputContainer {
  box-sizing: border-box;
  padding-bottom: 2px;
  border-bottom: solid 2px #b5bdd1;
  display: flex;
  align-items: flex-end;

  &:hover {
    border-bottom-color: #4b577e;
  }
}

.input {
  height: 34px;
  font-size: 14px;
  background: transparent;
  outline: none;

  &.disabled {
    background-color: #f9f9f9;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #96a1bd;
  }
}
