.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper.wrapperMultiLine {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.button {
  background-color: #cdcdcd;
  border: solid 1px #c2c2c2;
  border-radius: 2px;
  line-height: 1;
  text-align: center;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 9px 16px;
  min-width: 0;
}

.wrapperMultiLine .button {
  margin-right: 0;
}

.button:hover {
  background-color: #e0e0e0;
}

.button:last-child {
  margin-right: 0;
}

.buttonGrayTheme {
  background-color: #cdcdcd;
}

.buttonWhiteTheme {
  background-color: white;
}

.button.buttonNoSpace {
  border-radius: 0;
  margin-right: 0;
}

.button.buttonNoSpace:not(:last-child) {
  border-right: 0;
}

.button.buttonNoSpace:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button.buttonNoSpace:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-right: 0;
}

.noBorders {
  border: none;
  padding: 10px 16px;
}

.button.buttonSelected {
  background-color: #4285f4;
  border-color: transparent;
  color: white;
  font-weight: 500;
}

.adjustTheme {
  & .button {
    /*background-color: #96a1bd;*/

    &:hover {
      background-color: #8693b3;
    }

    &.buttonSelected {
      background-color: #96a1bd;
      color: white;
    }

    &.buttonDisabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.button.buttonDisabled {
  background-color: #f3f5f9;
}

.button.buttonSmallPadding {
  padding: 9px 5px;
}

.button.buttonClickable {
  cursor: pointer;
}

.label {
  min-width: 0;
  overflow-wrap: break-word;
}

.label.labelNoWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
