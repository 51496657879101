:global(.DayPicker) {
  background-color: #4b577e;
  color: #96a1bd;
  border-radius: 0;
}

:global(.DateRangePicker_picker):not(:global(.DateRangePicker_picker__portal)) {
  transform: translateY(-32px);
}

:global(.DateRangePicker--centered)
  :global(.DateRangePicker_picker):not(:global(.DateRangePicker_picker__portal)) {
  left: 50% !important;
  /* 60px is half width of the date period options panel */
  transform: translate(calc(-50% + 60px), -32px);
}

:global(.DateRangePicker_picker__portal) :global(.DayPicker) {
  transform: translateX(50px);
}

:global(.DateInput_fang) {
  transform: translateY(-31px);
  z-index: 5;
}

:global(.DateInput_fangShape) {
  fill: #4b577e;
}

:global(.CalendarMonth),
:global(.CalendarMonthGrid),
:global(.DayPickerNavigation_button) {
  background-color: inherit;
}

:global(.DayPickerNavigation_svg__horizontal) {
  fill: #8d97b3;
}

:global(.DayPickerNavigation_button) {
  border: 0;
}

:global(.CalendarMonth_caption) {
  color: white;
  font-size: 14px;
  font-weight: 300;
}

:global(.DayPicker_weekHeader) {
  color: white;
  font-weight: 600;
}

:global(.CalendarDay),
:global(.CalendarDay__default),
:global(.CalendarDay__default):hover {
  background-color: inherit;
  border-color: transparent;
  color: white;
  position: relative;
}

:global(.CalendarDay) {
  &:before {
    content: '';
    background: transparent;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -12px;
  }

  &:focus {
    outline: none;
  }

  &:global(.CalendarDay__selected_start),
  &:global(.CalendarDay__selected_end) {
    color: #5e6b8b;
    position: relative;

    &:before {
      background: white;
    }
  }

  &:global(.CalendarDay__selected_span) {
    &:before {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  &:global(.CalendarDay__blocked_out_of_range) {
    color: #5e6b8b;
  }

  &:not(:global(.CalendarDay__selected_start)):not(:global(.CalendarDay__selected_end)) {
    &:not(:global(.CalendarDay__blocked_out_of_range)):hover:before {
      background-color: #5e6b8b;
    }

    &:global(.CalendarDay__today):before {
      background: rgba(94, 107, 139, 0.5);
    }
  }
}

:global(.DateRangePickerInput) {
  background: transparent;
  border: none;
  cursor: pointer;
}

:global(.DateInput) {
  background: transparent;
  padding: 0;
  width: 85px;
}

:global(.DateInput_input) {
  background: transparent;
  color: #4b577e;
  cursor: inherit;
  padding: 0;
}

:global(.DateRangePickerInput_arrow) {
  margin-right: 4px;
  color: #4b577e;
}
