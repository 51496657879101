@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  border: 3px solid #cfd5dd;
  border-left-color: #445076;
  height: 40px;
  width: 40px;
  display: block;
  border-radius: 20px;
  animation: spinner 2s linear infinite;
}
