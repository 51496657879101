.root :global(.DateRangePicker_picker) {
  z-index: var(--date-range-picker-z-index);
  /*z-index: 1000;*/
}

.buttonContainer {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 20px;
}

.lastDaysPanel {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  bottom: 0;
}

.selectedDatesNotice {
  margin-right: 6px;
}

.option {
  cursor: pointer;
  padding: 6px 20px;
}

.option:not(.optionDisabled):hover {
  background-color: #f3f5f9;
}

.option:not(.optionDisabled).optionSelected {
  color: white;
  background-color: #4285f4;
}

.option.optionDisabled {
  cursor: initial;
  color: #c2c2c2;
}

.option.customOption {
  cursor: default;
}

.themeAdjust {
  & .lastDaysPanel {
    border: 0;
    background-color: #4b577e;
    left: 1px;
  }

  & .option {
    color: white;
  }

  & .option:not(.optionDisabled):hover {
    background-color: #5d6a8c;
  }

  & .option:not(.optionDisabled).optionSelected {
    color: #96a1bd;
    background-color: inherit;
  }

  & .option.optionDisabled {
    cursor: initial;
    color: #c2c2c2;
  }

  &.buttonContainer {
    border-top: 0;
  }
}

.option {
  cursor: pointer;
  padding: 6px 20px;
}

.option:not(.optionDisabled):hover {
  background-color: #f3f5f9;
}

.option:not(.optionDisabled).optionSelected {
  color: white;
  background-color: #4285f4;
}

.option.optionDisabled {
  cursor: initial;
  color: #c2c2c2;
}

.option.customOption {
  cursor: default;
}
