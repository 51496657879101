.resizeHandler {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  /* draggable zone size */
  width: 8px;
  cursor: ew-resize;
}

.resizeHandler:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 4px;
  height: 100%;
  /* viewable size */
  width: 4px;
  background-color: #4285f4;
  opacity: 0;
}

.resizeHandler:hover:after {
  opacity: 1;
}

.dragShadow {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  z-index: 10;
  background-color: #4285f4;
}

:global(.react-draggable-transparent-selection) * {
  cursor: ew-resize !important;
}
