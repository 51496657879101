@import url('~normalize.css/normalize.css');

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body,
#root {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--global-font-family);
  background-color: var(--global-background-color);
  color: var(--global-text-color);
  font-size: 13px;
  line-height: 1.42857143;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#root {
  min-width: 970px;
}

a {
  font-weight: normal;
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

a:link {
  color: #4285f4;
}

a:visited {
  color: #4285f4;
}

a:hover {
  color: #000;
}

a:active {
  color: #4285f4;
}

a:focus {
  text-decoration: none;
}

b {
  font-weight: 500;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.select2-container {
  z-index: 1501;
}
