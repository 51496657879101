.tabsContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  scrollbar-width: none;
  height: 36px;

  & a:not(.reportDropdownOptionLink) {
    color: #96a1bd;
    font-size: 16px;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
    &:hover,
    &:active,
    &:visited,
    &:link {
      text-decoration: none;
    }
  }
  & > :not(:first-child):not(:last-child) {
    margin-left: 16px;
  }

  & a:hover:not(.reportDropdownOptionLink),
  & a.active:not(.reportDropdownOptionLink) {
    color: #4b577e;
    z-index: 1;

    &:not(.nonUnderlined) {
      border-bottom: 2px solid #4b577e;
    }
  }
}

.tabsContainer:after {
  content: '';
  background: #e4e7ed;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabsContainer::-webkit-scrollbar {
  display: none;
}
